import React from "react";
import { Element } from "react-scroll";
import {
  Typography,
  Grid,
  Avatar,
  Container,
  IconButton,
  Link,
} from "@material-ui/core";
import { ReactTyped } from "react-typed";
import profilePicture from "../assets/img/tommy_profile_pic.png";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import "../styles/Home.css";

function Home() {
  return (
    <Element name="home" className="home-section">
      <Container maxWidth="xl">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <Avatar
              src={profilePicture}
              alt="Profile Picture"
              className="profileImageLarge"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="homeSection">
              <Typography variant="h2" className="homeTitle">
                <ReactTyped
                  strings={["Welcome to My Portfolio."]}
                  typeSpeed={100}
                  backSpeed={50}
                  loop
                  startDelay={50}
                  backDelay={3000}
                />
              </Typography>
              <Typography variant="h5" className="homeSubtitle">
                Tommy DeSollar
              </Typography>
              <Typography variant="body1" className="description">
                Marketing Student | Innovator | Leader
              </Typography>
              <Typography variant="body1" className="funnyQuote">
                "Seize every opportunity for growth."
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="socialIcons">
        <IconButton
          color="primary"
          component="a" // Use native anchor tags
          href="https://www.linkedin.com/in/tommy-desollar-597398236/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </IconButton>

        <IconButton
          color="primary"
          component={Link}
          href="mailto:tommyjdesollar@gmail.com"
          target="_blank"
          rel="noopener"
        >
          <EmailIcon />
        </IconButton>
      </div>
    </Element>
  );
}

export default Home;
