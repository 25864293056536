import React, { useEffect, useRef } from "react";
import ProjectCard from "./ProjectCard";
import { Grid, Typography } from "@material-ui/core";
import { Element } from "react-scroll";
import pinseekersLogo from "../assets/img/pinseekers_project.png";
import pgaLogo from "../assets/img/pga_project.png";
import "../styles/Projects.css";

function Projects() {
  const projects = [
    {
      name: "Marketing Research, Pinseekers Project",
      description: {
        overallDescription:
          "Led a comprehensive marketing research project for Pinseekers, focusing on identifying business challenges and growth opportunities. Conducted client interviews and extensive data analysis to optimize membership offerings and enhance customer engagement.",
        achievements: [
          "Revitalized customer interest and increased revenue streams by optimizing membership offerings.",
          "Conducted surveys with over 100 customers, leading to actionable insights on target demographics.",
          "Utilized advanced Excel functions to analyze data and uncover new revenue opportunities.",
        ],
        keyFeatures: [
          "Client Interviews: Conducted in-depth interviews to uncover key business insights.",
          "Data Analysis: Leveraged surveys and Excel for strategic recommendations.",
          "Membership Optimization: Enhanced offerings to boost engagement and revenue.",
        ],
        useCase:
          "Provided actionable insights to improve business performance and customer satisfaction, leading to a significant increase in customer engagement and revenue.",
      },
      logo: pinseekersLogo,
      techs: ["Client Interviews", "Data Analysis", "Excel", "Survey Design"],
    },
    {
      name: "PGA of America HQ Drive, Chip & Putt Event",
      description: {
        overallDescription:
          "Successfully organized and executed a high-profile event for the PGA of America, focusing on participant engagement and operational excellence.",
        achievements: [
          "Increased participation by 20% compared to the previous year.",
          "Coordinated event logistics, including itinerary, invitations, and volunteer management.",
        ],
        keyFeatures: [
          "Event Planning: Developed comprehensive event itinerary and logistics.",
          "Participant Engagement: Enhanced experience for over 60 participants.",
          "Award Ceremony: Hosted and distributed rewards to competition winners.",
        ],
        useCase:
          "Demonstrated expertise in event management and participant engagement for large-scale events.",
      },
      logo: pgaLogo,
      techs: [
        "Event Planning",
        "Logistics Management",
        "Volunteer Coordination",
      ],
    },
  ];

  const projectRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.1 },
    );

    const currentRefs = projectRefs.current;
    currentRefs.forEach((ref, index) => {
      if (ref) {
        if (index === 0) {
          ref.classList.add("in-view");
        } else {
          observer.observe(ref);
        }
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <Element name="projects" className="section projects-section">
      <Typography className="projectTitle" variant="h5" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={3} direction="column" alignItems="center">
        {projects.map((project, index) => (
          <Grid
            item
            xs={12}
            key={index}
            className="projectCard"
            ref={(el) => (projectRefs.current[index] = el)}
          >
            <ProjectCard project={project} />
          </Grid>
        ))}
      </Grid>
    </Element>
  );
}

export default Projects;
