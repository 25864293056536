import React, { useEffect, useRef } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CodeIcon from "@material-ui/icons/Code";
import GroupIcon from "@material-ui/icons/Group";
import "../styles/ProjectCard.css";

function ProjectCard({ project }) {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.1 },
    );

    const currentRef = cardRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const getIconForTech = (tech) => {
    switch (tech) {
      case "Client Interviews":
      case "Event Planning":
        return <BusinessIcon />;
      case "Data Analysis":
      case "Survey Design":
        return <AssessmentIcon />;
      case "Excel":
      case "Logistics Management":
        return <CodeIcon />;
      case "Volunteer Coordination":
        return <GroupIcon />;
      default:
        return null;
    }
  };

  return (
    <Card className="root" ref={cardRef}>
      <div className="mediaContainer">
        <img className="media" src={project.logo} alt={project.name} />
      </div>
      <CardContent className="cardTextContent">
        <Typography
          className="projectName"
          gutterBottom
          variant="h5"
          component="h2"
        >
          {project.name}
        </Typography>
        <Typography
          className="projectDescription"
          variant="body2"
          component="p"
        >
          <span className="descriptionTitle">Description:</span>{" "}
          {project.description.overallDescription}
        </Typography>
        <Typography
          className="projectAchievements"
          variant="body2"
          component="p"
        >
          <span className="descriptionTitle">Achievements:</span>
          <ul>
            {project.description.achievements.map((achievement, index) => (
              <li key={index}>{achievement}</li>
            ))}
          </ul>
        </Typography>
        <Typography
          className="projectKeyFeatures"
          variant="body2"
          component="p"
        >
          <span className="descriptionTitle">Key Features:</span>
          <ul>
            {project.description.keyFeatures.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </Typography>
        <Typography className="projectUseCase" variant="body2" component="p">
          <span className="descriptionTitle">Use Case:</span>{" "}
          {project.description.useCase}
        </Typography>
        <Typography className="projectUseCase" variant="body2" component="p">
          <span className="descriptionTitle">Technologies & Skills:</span>
          <ul className="techList">
            {project.techs.map((tech, index) => (
              <li key={index}>
                {getIconForTech(tech)} {tech}
              </li>
            ))}
          </ul>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ProjectCard;
