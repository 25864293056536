import React from "react";
import { Element } from "react-scroll";
import { Typography, Grid, Container } from "@material-ui/core";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import FlightIcon from "@material-ui/icons/Flight";
import GroupIcon from "@material-ui/icons/Group";
import MarketingIcon from "@material-ui/icons/TrendingUp";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import CloudIcon from "@material-ui/icons/Cloud";
import profilePicture from "../assets/img/tommy_about.png";
import "../styles/About.css";

function About() {
  return (
    <Element name="about" className="about-section">
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <img
              src={profilePicture}
              alt="Tommy DeSollar"
              className="profileImage"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="aboutSection">
              <Typography className="aboutMeTitle" variant="h5" gutterBottom>
                About Me
              </Typography>
              <Typography className="aboutMe" variant="body1" gutterBottom>
                My name is Tommy DeSollar, and I am a Senior at the University
                of Iowa, majoring in Marketing with a minor in Sport and
                Recreation Management. I am passionate about leveraging my
                marketing expertise and leadership skills in a dynamic,
                fast-paced environment. With a strong foundation in sales and
                marketing, I have successfully completed internships that honed
                my ability to solve complex business challenges. My leadership
                roles in student organizations have equipped me with the skills
                to thrive in innovative settings. I am eager to contribute to a
                forward-thinking team and make a meaningful impact in the
                industry.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="hobbiesSection">
              <Typography className="sectionTitle" variant="h5" gutterBottom>
                Hobbies
              </Typography>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12} sm={4} className="hobbyItem">
                  <FitnessCenterIcon className="hobbyIcon" />
                  <Typography variant="body1" className="interestText">
                    Exercise
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="hobbyItem">
                  <FlightIcon className="hobbyIcon" />
                  <Typography variant="body1" className="interestText">
                    Travel
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="hobbyItem">
                  <GroupIcon className="hobbyIcon" />
                  <Typography variant="body1" className="interestText">
                    Quality Time w/ Family & Friends
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="interestsSection">
              <Typography className="sectionTitle" variant="h5" gutterBottom>
                Interests in Tech
              </Typography>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12} sm={4} className="interestItem">
                  <MarketingIcon className="interestIcon" />
                  <Typography variant="body1" className="interestText">
                    Digital Marketing Technologies
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="interestItem">
                  <SportsEsportsIcon className="interestIcon" />
                  <Typography variant="body1" className="interestText">
                    Advanced Sports Data Analytics
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className="interestItem">
                  <CloudIcon className="interestIcon" />
                  <Typography variant="body1" className="interestText">
                    Data Center AI Solutions
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Element>
  );
}

export default About;
