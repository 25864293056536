import React, { useState } from "react";
import { Element } from "react-scroll";
import { Typography, Grid, Paper, Button, Container } from "@material-ui/core";
import resume from "../assets/pdf/Tommy_DeSollar_Resume.pdf";
import "../styles/Resume.css";

function Resume() {
  const [isDownloaded, setIsDownloaded] = useState(false);

  const handleDownload = () => {
    setIsDownloaded(true);
  };

  return (
    <Element name="resume" className="resume-section">
      <Container maxWidth="lg">
        <Typography className="resumeTitle" variant="h4" gutterBottom>
          My Resume
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} className="paper">
              <Typography variant="h5" className="sectionTitle highlight">
                Summary
              </Typography>
              <Typography variant="body1" className="sectionContent">
                Driven Sales and Marketing professional with experience in
                operations, client relations, and event management in sports and
                business environments. Pursuing a Bachelor’s in Marketing with a
                minor in Sport and Recreation Management. Skilled in client
                service, team coordination, and CRM tools. Seeking to contribute
                strong problem-solving and multitasking abilities to a dynamic
                organizations success.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} className="paper">
              <Typography variant="h5" className="sectionTitle highlight">
                Education
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>The University of Iowa</strong> <br />
                <em>Bachelor of Business Administration (BBA), Marketing</em>
                <br />
                Minor in Sport and Recreation Management <br />
                <em>Anticipated May 2025</em>
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>Dean's List</strong>
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} className="paper">
              <Typography variant="h5" className="sectionTitle highlight">
                Experience
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>University of Iowa</strong> <br />
                Operations Student Manager <br />
                <em>Aug 2023 - Present</em> <br />
                • Optimized equipment logistics and practice setups, ensuring
                efficient resource allocation for team drills and games. <br />
                • Managed off-season and game-day operations, securing supplies
                and streamlining event flow. <br />• Excelled in balancing
                full-time responsibilities with academics, demonstrating strong
                time management and adaptability.
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>PGA of America</strong> <br />
                Player Engagement Intern <br />
                <em>Jun 2024 - Jul 2024</em> <br />• Oversaw Drive, Chip & Putt
                event for 50+ participants, coordinating logistics and enhancing
                participant experience. <br />• Engaged with PGA Jr. League
                customers via Intercom, resolving inquiries swiftly and
                effectively.
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>Cottingham & Butler</strong> <br />
                Client Services Representative <br />
                <em>May 2023 - Aug 2023</em> <br />
                • Leveraged CRM software to track and manage client
                interactions, improving service response times. <br />• Resolved
                high volumes of client inquiries and facilitated clear, timely
                communication between clients and underwriters.
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>PGA Championship 2023</strong> <br />
                Merchandise Operations Intern <br />
                <em>Apr 2023 - May 2023</em> <br />
                • Supported 45 vendors in merchandise distribution, optimizing
                sales operations and inventory management. <br />• Led a team of
                8-12 volunteers to ensure seamless event operations and positive
                customer experiences.
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>University of Iowa</strong> <br />
                Athletic Facilities Crew Member <br />
                <em>Aug 2022 - Apr 2023</em> <br />• Coordinated logistics for
                athletic events, preparing facilities for thousands of
                spectators and ensuring operational readiness.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} className="paper">
              <Typography variant="h5" className="sectionTitle highlight">
                Skills & Certifications
              </Typography>
              <Typography variant="body1" className="sectionContent">
                • Team Coordination | Client Services | Problem Solving | Time
                Management <br />• Certification: Marketing Strategy:
                Competitive Intelligence
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={3} className="paper">
              <Typography variant="h5" className="sectionTitle highlight">
                Volunteer Experience
              </Typography>
              <Typography variant="body1" className="sectionContent">
                <strong>Operation Tiny House</strong> <br />
                Volunteer <br />
                <em>Jun 2024 - Aug 2024</em> <br />
                • Assisted in building tiny homes for veterans, supporting
                sustainable housing initiatives. <br />• Collaborated with
                volunteers to streamline project tasks and maintain a safe,
                productive worksite.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              href={resume}
              download="Tommy_DeSollar_Resume.pdf"
              className="button"
              onClick={handleDownload}
            >
              Download Resume
            </Button>
            {isDownloaded && (
              <Typography variant="body1" className="thankYouMessage">
                Thank you for downloading my resume! 😊
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </Element>
  );
}

export default Resume;
